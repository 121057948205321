import styled from "styled-components";

export const Wrapper = styled.div`
  .team-dashboard {
    margin-top: 50px;
    .project-sum {
      height: 100%;
      min-heght: 300px;
      .item {
        position: relative;
        top: 50px;
      }
    }
    .sdt-container {
      height: 100%;
      .center {
        text-align: center;
        span {
          display: block;
          font-size: 40px;
          font-weight: bold;
          &.top {
            border-bottom: 1px solid rgb(227, 227, 227);
            padding: 10px 0 15px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .summary-item {
    h4 {
      svg {
        margin-right: 5px;
      }
    }
    .line-height40 {
      line-height: 40px;
    }
    .filters-wrapper {
      & > div {
        margin-left: 15px;
      }
      .dropdown {
        min-width: 150px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
  .detail-item {
    .pie-chart-container {
      width: 300px;
      margin: auto;
      &.total-projects {
        position: relative;
        .total {
          position: absolute;
          top: 40%;
          left: 0;
          right: 0;
          text-align: center;
          h2 {
            margin-bottom: -10px;
          }
          p {
            font-size: 30px;
          }
        }
        p.cancelled {
          font-size: 13px;
          position: absolute;
          right: -80px;
          bottom: -20px;
          font-weight: normal;
          span {
            font-weight: bold;
          }
          &::before {
            content: "";
            background: #f42b2b;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    &.scroll {
      height: 100%;
      max-height: 300px;
      overflow: scroll;
      &.big {
        max-height: 235px;
      }
      &.dev {
        max-height: 180px;
      }
    }

    &.icon {
      svg {
        width: 200px;
        margin: auto;
      }
    }
    .item {
      p {
        padding-bottom: 10px;
      }

      svg {
        margin-top: 5px;
        width: 44px;
        margin-bottom: -5px;
      }
      &.ontrack {
        svg {
          color: rgb(67, 185, 178);
        }
      }
      &.inprogress {
        svg {
          color: #fec84d;
        }
      }
      &.at-risk {
        svg {
          color: #fd7e40;
        }
      }
    }
    table {
      td,
      tr,
      th {
        border: 0;
        &:first-child {
          padding-left: 15px;
        }
        p {
          font-size: 14px;
          font-weight: 700;
          line-height: 150% !important;
          span {
            font-weight: 400;
            color: #767676;
            font-size: 13px;
          }
        }
        a {
          color: #000;
          &:hover {
            color: #0071cd;
          }
        }
      }
      td {
        padding: 5px 16px !important;
      }
      th {
        font-size: 16px;
        color: #767676;
      }
      &.task {
        th {
          font-size: 14px;
        }
      }
    }
  }
  .pmo-pagination {
    p {
      &.MuiTablePagination-selectLabel,
      &.MuiTablePagination-displayedRows {
        margin-bottom: 0 !important;
      }
    }
  }

  .highlight-projects {
    .left-section,
    .right-section {
      padding: 20px;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      .view-detail {
        positive: absolute;
        text-align: right;
        a {
          font-size: 13px;
        }
      }
    }

    .wrapper {
      display: flex;
      gap: 2;
      height: 200px;
      border: 1px solid rgb(227, 227, 227);
    }
    .highlight-bg {
      &.yellow {
        background: rgba(254, 200, 77, 0.2);
      }
      &.green {
        background: rgba(0, 177, 176, 0.2);
      }
      &.red {
        background: rgba(255, 131, 112, 0.2);
      }

      .top-section {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        h3 {
          font-size: 22px;
          line-height: 125%;
        }
        p {
          position: absolute;
          left: 20px;
          bottom: 0;
          font-size: 13px;
          span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-left: 5px;
            &.yellow {
              background: rgba(254, 200, 77, 1);
            }
            &.green {
              background: rgba(0, 177, 176, 1);
            }
            &.red {
              background: rgba(255, 131, 112, 1);
            }
          }
        }
      }
    }

    .middle-section {
      position: relative;
      padding-top: 20px;
      .status {
        position: absolute;
        right: 0;
        top: -10px;
        background: #168ae9;
        color: #fff;
        text-transform: uppercase;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 12px;
      }
      h5 {
        font-size: 15px;
        margin-bottom: 10px;
        span {
          font-weight: 400;
        }
      }
    }
  }

  .resources {
    h4.total {
      font-size: 20px;
    }
    .item {
      height: 215px !important;
      h3 {
        padding-top: 10px;
        line-height: 75%;
        span {
          font-weight: 400;
        }
      }
    }
  }

  .demand-table-container {
    width: 65%;
    transition: opacity 1s ease-in;
    max-height: 350px;
    overflow-y: auto;
    border-right: 1px solid rgb(227, 227, 227);
    padding-right: 30px;
    .demand-table-wrapper {
      position: relative;
      .status {
        position: absolute;
        right: 0;
        top: 10px;
        background: #168ae9;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        padding: 5px 15px;
        border-radius: 5px;
      }
    }
  }
`;
