import { adminContextStore } from "../context/store/createStore";
import { SET_RESOURCES } from "../context/actions/resources";
import { getResources } from "../httpRequests/resources";

export default async () => {
  const { dispatch } = adminContextStore;

  dispatch({ type: SET_RESOURCES, payload: { loading: true } });

  try {
    const response = await getResources();
    const mainResources = response?.data?.filter(i => i.vendor === 'Total Resources')
    const total = mainResources?.reduce((acc, item) => acc + item.total, 0);
    dispatch({
      type: SET_RESOURCES,
      payload: {
        loading: false,
        resources: response.data,
        total: total,
        error: null,
      },
    });

    return response;
  } catch (error) {
    dispatch({
      type: SET_RESOURCES,
      payload: {
        loading: false,
        error: error.message || "Failed to load resources",
      },
    });
    return null;
  }
};
