import React, { useState, useMemo, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import { PencilIcon } from "@heroicons/react/24/outline";
import { patchResource } from "../../httpRequests/resources";
import { SET_SNACKBAR } from "../../context/actions/snackbar";
import { useAdminDispatch } from "../../context/AdminContext";

function ByDomain({ resources, refetch, setRefetch }) {
  const dispatch = useAdminDispatch();
  const [open, setOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [formData, setFormData] = useState({});

  const handleOpen = useCallback((resource) => {
    setSelectedResource(resource);
    setFormData({
      position: resource.position,
      total: resource.total,
      salary: resource.salary,
      periodContract: resource.periodContract,
      name: resource.name,
    });
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedResource(null);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const dispatchSnackbar = useCallback(
    (success, message) => {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          open: true,
          message,
          severity: success ? "success" : "error",
        },
      });
    },
    [dispatch]
  );

  const handleSave = async () => {
    const updatedData = {
      ...formData,
      updatedBy: "Parama",
      vendor: "By Domain",
    };

    try {
      const response = await patchResource(selectedResource.id, updatedData);
      dispatchSnackbar(
        response.status === 200,
        response.status === 200
          ? "Updated Successfully"
          : response.error.description
      );
      setRefetch(!refetch);
    } catch {
      dispatchSnackbar(false, "Failed to update resource");
    }
    handleClose();
  };

  const mainResources = useMemo(
    () => resources.filter((row) => row.position !== "OS"),
    [resources]
  );

  const osResources = useMemo(
    () => resources.filter((row) => row.position === "OS"),
    [resources]
  );

  const subtotal = useMemo(
    () =>
      mainResources.reduce(
        (totals, row) => ({
          approve: totals.approve + row.total,
          active: totals.active + row.salary,
          vacant: totals.vacant + row.periodContract,
        }),
        { approve: 0, active: 0, vacant: 0 }
      ),
    [mainResources]
  );

  const total = useMemo(
    () =>
      resources.reduce(
        (totals, row) => ({
          approve: totals.approve + row.total,
          active: totals.active + row.salary,
          vacant: totals.vacant + row.periodContract,
        }),
        { approve: 0, active: 0, vacant: 0 }
      ),
    [resources]
  );

  const TableRowComponent = useCallback(
    ({ id, position, total, salary, periodContract, name }) => (
      <TableRow>
        <TableCell align="center">{position}</TableCell>
        <TableCell align="center">{total}</TableCell>
        <TableCell align="center">{salary}</TableCell>
        <TableCell align="center">{periodContract}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>
          <IconButton
            onClick={() =>
              handleOpen({ id, position, total, salary, periodContract, name })
            }
          >
            <PencilIcon style={{ width: 20, height: 20 }} />
          </IconButton>
        </TableCell>
      </TableRow>
    ),
    [handleOpen]
  );

  const commonStyles = useMemo(
    () => ({
      subtotalRow: { backgroundColor: "rgb(227, 227, 227)" },
    }),
    []
  );

  return (
    <>
      <TableContainer>
        <Table aria-label="resource table">
          <TableHead>
            <TableRow>
              <TableCell align="center">TH (Hcs)</TableCell>
              <TableCell align="center">Approve</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Vacant</TableCell>
              <TableCell>Domain</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {mainResources.map((row) => (
              <TableRowComponent key={row.id} {...row} />
            ))}
            <TableRow sx={commonStyles.subtotalRow}>
              <TableCell align="center" colSpan={1}>
                <strong>Subtotal</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{subtotal.approve}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{subtotal.active}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{subtotal.vacant}</strong>
              </TableCell>
              <TableCell colSpan={2} />
            </TableRow>
            {osResources.map((row) => (
              <TableRowComponent key={row.id} {...row} />
            ))}
            <TableRow sx={commonStyles.subtotalRow}>
              <TableCell align="center" colSpan={1}>
                <strong>Total</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{total.approve}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{total.active}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{total.vacant}</strong>
              </TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Modify the details and click "Save" to apply changes.
          </DialogContentText>
          {formData && (
            <form>
              <TextField
                label="TH (Hcs)"
                name="position"
                value={formData.position}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Approve"
                name="total"
                type="number"
                value={formData.total}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Active"
                name="salary"
                type="number"
                value={formData.salary}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Vacant"
                name="periodContract"
                type="number"
                value={formData.periodContract}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Domain"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ByDomain;
